import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";

import store from "@/state/store";
import routes from "./routes";
import { PositionResult, Position } from "vue-router/types/router";
import TrackingService from "@/services/trackingservice/tracking.service";
import { checkAppVersion, detectAdBlocker, redirectHandler } from "./helpers";
import { helpers } from "@/services/helpers";
import { loadConfigAndSetLinks } from "@/composables/supportLinks";
import {
  isShowingMushModal,
  updateMushContent,
} from "@/components/must-share-content/mustShareContent";
import { updateAlertData } from "@/components/alerts/alertContent";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(
    routeTo: Route,
    routeFrom: Route,
    savedPosition: void | Position
  ): PositionResult {
    /**
     * Scroll to a hash
     */
    if (routeTo.hash) {
      return { selector: routeTo.hash, offset: { y: 100, x: 0 } };
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const maxRedirects = 5;
let redirectCount = 0;

// Before each route evaluates...
router.beforeEach(
  async (routeTo: Route, routeFrom: Route, next: NavigationGuardNext) => {
    if (routeTo.path === "/too-many-redirects") {
      redirectCount = 0;
      next();
      return;
    }
    if (redirectCount > maxRedirects) {
      helpers.customSentryError(
        "Too Many Redirects Error",
        "Max redirects count hit.",
        {
          routeTo: routeTo.fullPath,
          routeFrom: routeFrom.fullPath,
        }
      );
      next("/too-many-redirects");
      return;
    }

    await detectAdBlocker();

    const result = redirectHandler(routeTo, store.getters["auth/authUser"]);

    if (result && result !== "") {
      redirectCount = redirectCount + 1;

      next(result);
      return;
    }

    if (store.getters["auth/loggedIn"]) {
      updateAlertData();
      isShowingMushModal.value = false;
      updateMushContent();
    }

    redirectCount = 0;
    next();
    return;
  }
);

router.beforeResolve(
  async (routeTo: Route, routeFrom: Route, next: NavigationGuardNext) => {
    try {
      // For each matched route...
      for (const route of routeTo.matched) {
        await new Promise<void>((resolve, reject) => {
          // If a `beforeResolve` hook is defined, call it with
          // the same arguments as the `beforeEnter` hook.
          if (route.meta && route.meta.beforeResolve) {
            route.meta.beforeResolve(
              routeTo,
              routeFrom,
              (...args: string[]) => {
                // If the user chose to redirect...
                if (args.length) {
                  // If redirecting to the same route we're coming from...
                  // Complete the redirect.
                  next(...args);
                  reject(new Error("Redirected"));
                } else {
                  resolve();
                }
              }
            );
          } else {
            // Otherwise, continue resolving the route.
            resolve();
          }
        });
      }
      // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
      return;
    }

    // If we reach this point, continue resolving the route.
    next();
  }
);

router.afterEach((routeTo: Route) => {
  checkAppVersion();

  loadConfigAndSetLinks(routeTo.path);

  if (store.getters["auth/loggedIn"]) {
    const { name, fullPath, hash, params, path, query } = routeTo;

    const routeClean = { name, fullPath, hash, params, path, query };
    TrackingService.trackEvent("pageView", { to: routeClean });
    try {
      window.convertflow?.start();
    } catch (err) {
      console.log("Convertflow failed to detect page change:" + err);
    }
  }
});

export default router;
