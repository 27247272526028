import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/resources",
    name: "Resources",
    meta: { authRequired: true },
    component: () => import("@/router/views/resources/agent-resources.vue"),
  },

  {
    path: "/resources/templates/:slug",
    name: "Resource Details",
    props: true,
    meta: { authRequired: true },
    component: () => import("@/router/views/resources/resourceDetail.vue"),
  },
  {
    path: "/resources/buyers-purchasing-power",
    name: "Buyer's Purchasing Power",
    meta: { authRequired: true },
    component: () => import("@/router/views/resources/bpp.vue"),
  },
  {
    path: "/resources/hpes",
    name: "Home Price Expectations Survey",
    meta: { authRequired: true },
    component: () => import("@/router/views/resources/hpes.vue"),
  },
  {
    path: "/guides/:guideSlug",
    name: "Guide Details",
    props: true,
    meta: { authRequired: true },
    component: () => import("@/router/views/resources/guideDetail.vue"),
  },
  {
    path: "/resources/pear",
    name: "PEAR Report Tool",
    meta: { authRequired: true },
    component: () => import("@/router/views/resources/pear-page.vue"),
  },
];

const redirects: RouteConfig[] = [
  {
    path: "/infographics/reasons-to-own",
    redirect: "/resources",
  },
  {
    path: "/infographics/cost-of-waiting",
    redirect: "/resources",
  },
  {
    path: "/infographics/quotes/",
    redirect: "/resources",
  },
  {
    path: "/guides",
    redirect: "/resources",
  },
  {
    path: "/buyersellerguides",
    redirect: "/resources",
  },
  { path: "/content/templates", redirect: "/resources" },
];

const industryRoutes = [...routes, ...redirects];

export default industryRoutes;
