import {
  KcmAsset,
  KcmAssetArrayResponse,
  KcmAssetArrayResult,
  KcmAssetResponse,
  KcmCategoryResult,
  KcmContentCreate,
  KcmContentUpdate,
  KcmTranslation,
} from "../../types/index";
import {
  KcmCategory,
  KcmCategoryResponse,
  KcmContent,
  KcmContentArrayResponse,
  KcmContentArrayResult,
  KcmContentResponse,
  KcmContentResult,
} from "@/types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { DateTimeFormatOptions } from "vue-i18n";
import { useContentTypeUtils } from "@/composables/contentUtils";
import store from "../../state/store";
import i18n from "@/i18n";
import customContent from "@/services/internal-api/content/customContent";
import customAsset from "@/services/internal-api/content/customAsset";
import cache from "@/services/cache/cache.service";

const industry = "1";

const baseUrl = process.env.VUE_APP_CONTENT_API_ROOT
  ? process.env.VUE_APP_CONTENT_API_ROOT.replace(/\/$/, "")
  : "";

const CONTENT_STATUSES = {
  standard: ["published", "public"],
  editor: ["published", "draft"],
};
let DEFAULT_CONTENT_STATUSES = CONTENT_STATUSES.standard;

const CATEGORY_TYPES = {
  standard: ["standard"],
  training: ["training"],
};

const DEFAULT_CATEGORY_TYPES = CATEGORY_TYPES.standard;

/**
 * Define a list of known errors when working with
 * the content api
 */
const Errors = {
  ContentTypesErr: "unable to load content types",
  ContentsErr: "unable to load content",
  CategoriesErr: "unable to load categories",
};

const { convertContentType } = useContentTypeUtils();

export function authHeaders(): AxiosRequestConfig {
  const override = new URLSearchParams(window.location.search).get("override");
  if (override) {
    DEFAULT_CONTENT_STATUSES = ["all"];
    return {
      auth: { username: "", password: override },
    };
  }

  const userToken = store.getters["auth/authToken"];

  return {
    headers: {
      Authorization: "Bearer " + userToken,
    },
  };
}

export interface ContentQueryParams {
  tags?: string;
  page?: number;
  limit?: number;
  year?: number;
  month?: number;
  types?: string;
  categories?: string;
  status?: string[];
  language?: string;
  query?: string;
  published_since?: string;
  published_before?: string;
  user_id?: number;
  custom?: number;
  override?: number;
  generic?: number;
  webinar_time_since?: string;
  webinar_time_before?: string;
  exclude_ids?: number[];
}

export interface AssetQueryParams {
  language: string;
  tags?: string;
  categories?: string;
  prefix?: string;
  directory?: string;
  types?: string;
  updated_since?: string;
  user_id?: string;
  related_content?: string;
  page?: number;
  limit?: number;
}

export const contentService = {
  errors: Errors,

  accessRequirements: {
    script: ["realtalk"],
    videos: ["video"],
    video: ["video"],
  },
  getContents,
  getContentBySlug,
  getContentById,
  getCategories,
  formatDate,
  formatDateTime,
  formatDateShort,
  formatDateShortMonth,
  personalizedVideoUrl,
  currentMMRDate,
  showNewMMRAlert,
  getAsset,
  getAssets,
  getMostRecentBrunchNLearn,
  customContent: { ...customContent },
  customAsset: { ...customAsset },
  contentQueryString,
  makePostDataFromContent,
  makeUpdateFromContent,
  getMostRecentGettingStartedWebinar,
  getLatestMushContent,
  getLatestAlertContent,
};

/**
 * I'm annoying and set up the API so that you couldn't pass in the content_type
 * on an update request, but you can to create a piece of content... so these two
 * sets of data have to be different. I hate myself. No wonder my dad left me.
 * @param content
 * @returns
 */
export function makePostDataFromContent(
  content: Partial<KcmContent>
): KcmContentCreate {
  const { id, created_at, updated_at, ...rest } = content;

  if (content.translations) {
    (rest as KcmContentUpdate).translations = [];
    Object.entries(content.translations).forEach(
      ([key, value]: [string, KcmTranslation]) => {
        value.language = key;
        (rest as KcmContentUpdate).translations?.push(value);
      }
    );
  }

  (rest as KcmContentUpdate).categories =
    content.categories?.map((category) => category.slug) ?? [];

  (rest as KcmContentUpdate).assets =
    content.assets?.map((asset) => asset.id) ?? [];

  return rest as KcmContentCreate;
}

export function makeUpdateFromContent(
  content: Partial<KcmContent>
): KcmContentUpdate {
  /**
   * Now that we have the makePostDataFromContent method
   * we can just use that and then remove anything else
   * that needs to be removed for updates specifically, i.e. content_type
   */
  const postData = makePostDataFromContent(content);
  const { content_type, ...rest } = postData;

  return rest as KcmContentUpdate;
}

export function contentRoute(content: KcmContent): string {
  if (!content) return "";

  const typeRouteMap: Record<string, string> = {
    blog: "/shareable-content/blog/",
    video: "/shareable-content/video/",
    videos: "/shareable-content/video/",
    "social-graphics": "/shareable-content/graphic/",
    "social-graphic": "/shareable-content/graphic/",
    infographic: "/shareable-content/infographic/",
    mmr: "/market-updates/mmr/",
    "deep-dive": "/market-updates/deep-dive/",
    "best-practice": "/learning-center/best-practice/",
    script: "/content/scripts/",
  };

  // Ensuring the content_type is singular before matching
  const singularContentType = convertContentType(content.content_type, true);

  return `${typeRouteMap[singularContentType]}${content.slug}`;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function get(resource: string): Promise<AxiosResponse<any, any>> {
  const isAdmin = authHeaders().auth?.password;
  if (!isAdmin) {
    // check if this request has been cached previously, return if true
    const cachedResponse = cache.get("content", resource);

    if (cachedResponse) return Promise.resolve(cachedResponse);
  }
  // if not cached, make request, cache response, return response
  return (
    axios
      .get(baseUrl + resource, authHeaders())
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      .then((response: AxiosResponse<any, any>) => {
        if (!isAdmin) cache.set("content", resource, response);
        return response;
      })
  );
}

function contentQueryString(params: ContentQueryParams): string {
  const defaults: ContentQueryParams = {
    page: 0,
    limit: 10,
    language: "en",
    status: DEFAULT_CONTENT_STATUSES,
    generic: 1,
  };

  const allParams = { ...defaults, ...params };

  const queryParts: string[] = [];

  for (const key in allParams) {
    if (Object.prototype.hasOwnProperty.call(allParams, key)) {
      const value = allParams[key as keyof ContentQueryParams];
      if (value !== undefined && value !== "") {
        if (Array.isArray(value)) {
          queryParts.push(`${key}=${value.join(",")}`);
        } else if (key === "types") {
          // Convert types to singular
          const convertedTypes = value
            .toString()
            .split(",")
            .map((type) => convertContentType(type.trim(), true))
            .join(",");
          queryParts.push(`${key}=${encodeURIComponent(convertedTypes)}`);
        } else {
          queryParts.push(`${key}=${encodeURIComponent(value.toString())}`);
        }
      }
    }
  }

  return "?" + queryParts.join("&");
}

/**
 * Loads a batch of content from the content API
 * @param page
 * @param query
 * @param statuses
 * @returns
 */
function getContents(
  options: ContentQueryParams
): Promise<KcmContentArrayResult> {
  // Converting content types to singular for the API request here
  if (options.types) {
    options.types = options.types
      .split(",")
      .map((type) => convertContentType(type.trim(), true))
      .join(",");
  }

  const queryString = contentQueryString(options);

  const endpoint: string =
    "/industries/" +
    industry +
    "/content/" +
    (options.query ? "search/" : "") +
    queryString +
    "&sort_by=published_at";

  return get(endpoint)
    .then((result: KcmContentArrayResponse) => {
      if (result.data) {
        if (result.data.content) {
          result.data.content.forEach((pieceOfContent: KcmContent) => {
            // Convert content_type back to plural for consistency with existing code. Remove once API is updated
            pieceOfContent.content_type = convertContentType(
              pieceOfContent.content_type,
              false
            );
            replaceMemberAreaLinks(pieceOfContent);
          });
          return result.data;
        } else if (result.data.search_results) {
          result.data.content = result.data.search_results;
          result.data.content.forEach((pieceOfContent: KcmContent) => {
            // Convert content_type back to plural for consistency with existing code
            pieceOfContent.content_type = convertContentType(
              pieceOfContent.content_type,
              false
            );
            replaceMemberAreaLinks(pieceOfContent);
          });
          return result.data;
        }
      }
      return { error: Errors.ContentsErr };
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getContentBySlug(
  slug: string,
  lang = "en",
  statuses = DEFAULT_CONTENT_STATUSES
): Promise<KcmContentResult> {
  const endpoint =
    "/industries/" +
    industry +
    "/content/slug-" +
    slug +
    "?language=" +
    lang +
    "&status=" +
    statuses +
    "&user_id=" +
    store.getters["auth/authUserId"];

  return get(endpoint)
    .then((result: KcmContentResponse) => {
      if (result.data && result.data.content) {
        replaceMemberAreaLinks(result.data.content);
        return result.data;
      } else {
        return { error: "No content found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getContentById(
  id: number,
  lang = "en",
  statuses = DEFAULT_CONTENT_STATUSES
): Promise<KcmContentResult> {
  const endpoint =
    "/industries/" +
    industry +
    "/content/" +
    id +
    "?language=" +
    lang +
    "&status=" +
    statuses;

  return get(endpoint)
    .then((result: KcmContentResponse) => {
      if (result.data && result.data.content) {
        replaceMemberAreaLinks(result.data.content);
        return result.data;
      } else {
        return { error: "No content found matching these parameters" };
      }
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

/**
 * Loads a batch of content from the content API
 * @param page
 * @param query
 * @param statuses
 * @returns
 */
function getCategories(
  lang = "en",
  statuses = ["public"],
  categoryTypes = DEFAULT_CATEGORY_TYPES
): Promise<KcmCategoryResult> {
  const endpoint =
    "/industries/" +
    industry +
    "/categories/?limit=200&sort_by=name&sort_dir=asc&language=" +
    lang +
    "&status=" +
    statuses +
    "&category_type=" +
    categoryTypes;

  return get(endpoint)
    .then((result: KcmCategoryResponse) => {
      if (result.data && result.data.categories !== undefined) {
        result.data.categories = result.data.categories.filter(
          (category: KcmCategory) =>
            ![
              "featured",
              "outdated",
              "uncategorized",
              "time-sensitive",
            ].includes(category.slug)
        );
        return result.data;
      } else {
        return { error: Errors.CategoriesErr };
      }
    })
    .catch(() => {
      return { error: Errors.CategoriesErr };
    });
}

function replaceMemberAreaLinks(content: KcmContent): KcmContent {
  const replace = new RegExp("" + process.env.VUE_APP_MEMBER_PAGE_ROOT, "g");

  if (content.contents) {
    content.contents = content.contents.replace(replace, "/");
  }
  if (content.description) {
    content.description = content.description.replace(replace, "/");
  }

  return content;
}

function formatDate(date: string, language: string): string {
  const d = new Date(date);
  const options: DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return d.toLocaleDateString(language + "-US", options);
}

/**
 *
 * @returns
 *  example format: December 3, 2023
 */
function formatDateTime(date: string, language: string): string {
  const d = new Date(date);
  const options: DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    minute: "2-digit",
    hour: "numeric",
  };
  return d.toLocaleDateString(language + "-US", options);
}

/**
 *
 * @returns
 *  example format: Dec 3, 2023
 */
function formatDateShortMonth(date: string, language: string): string {
  const d = new Date(date);
  const options: DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return d.toLocaleDateString(language + "-US", options);
}

/**
 *
 * @returns
 *  example format: 5/4/2023 (no leading zeros)
 *  example format: 05/04/2023 (with leading zeros)
 */
function formatDateShort(
  date: string,
  language: string,
  leadingZeros = false
): string {
  const d = new Date(date);
  if (!leadingZeros) {
    return d.toLocaleDateString(language + "-US");
  } else {
    return d.toLocaleDateString(language + "-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }
}

function personalizedVideoUrl(url: string): string {
  return (
    url.replace(
      "videos.mykcm.com",
      "videos.mykcm.com/members/" + store.getters["auth/authUserId"]
    ) +
    "?t=" +
    Date.now()
  );
}

const mmrReleaseDate = 10;
const mmrReleaseHour = 11;
// const mmrReleaseSpanishDiff = 7;

const mmrHideAlertDate = mmrReleaseDate + 7;

function checkIfMMRReleaseForCurrentMonth(force = false): boolean {
  const today = new Date();
  const day = today.getDay(); //Sunday is 0
  const d = today.getDate();
  const h = today.getHours();

  return (
    (day === 5 &&
      (d === mmrReleaseDate - 1 || d === mmrReleaseDate - 2) &&
      h >= mmrReleaseHour) ||
    (day === 6 && d === mmrReleaseDate - 1) ||
    ((day === 5 || day === 6) && d === mmrReleaseDate) ||
    (d === mmrReleaseDate && h > mmrReleaseHour) ||
    d > mmrReleaseDate ||
    force
  );
}

function checkIfMMRReleaseForCurrentMonthPlusWeek(): boolean {
  const today = new Date();
  const d = today.getDate();
  const h = today.getHours();

  return (d === mmrHideAlertDate && h > mmrReleaseHour) || d > mmrHideAlertDate;
}

function showNewMMRAlert(): boolean {
  if (
    checkIfMMRReleaseForCurrentMonth(false) &&
    !checkIfMMRReleaseForCurrentMonthPlusWeek()
  ) {
    return true;
  }

  return false;
}

function currentMMRDate(force = false): {
  month: number;
  monthName: string;
  year: number;
} {
  const today = new Date();
  const m = today.getMonth() + 1; //accounting for January being 0
  const y = today.getFullYear();

  if (checkIfMMRReleaseForCurrentMonth(force)) {
    return {
      month: m,
      monthName: today.toLocaleString(i18n.locale, { month: "long" }),
      year: y,
    };
  }

  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);

  return {
    month: lastMonth.getMonth(),
    monthName: lastMonth.toLocaleString(i18n.locale, { month: "long" }),
    year: lastMonth.getFullYear(),
  };
}

function getAsset(id: number, options: AssetQueryParams): Promise<KcmAsset> {
  const {
    language = "en",
    tags = "",
    categories = "",
    prefix = "",
    directory = "",
    updated_since = "",
    user_id = "",
    related_content = "",
  } = options;

  const endpoint =
    "/industries/" +
    industry +
    "/assets/" +
    id +
    "?language=" +
    language +
    (tags ? "&tags=" + tags : "") +
    (categories ? "&categories=" + categories : "") +
    (prefix ? "&prefix=" + prefix : "") +
    (directory ? "&directory=" + directory : "") +
    (updated_since ? "&updated_since=" + updated_since : "") +
    (user_id ? "&user_id=" + user_id : "") +
    (related_content ? "&related_content=" + related_content : "");

  return get(endpoint)
    .then((result: KcmAssetResponse) => {
      return result.data.asset;
    })
    .catch(() => {
      throw new Error(Errors.ContentsErr);
    });
}

function getAssets(options: AssetQueryParams): Promise<KcmAssetArrayResult> {
  const {
    page = -1,
    limit = -1,
    language = "en",
    tags = "",
    categories = "",
    prefix = "",
    directory = "",
    updated_since = "",
    user_id = "",
    related_content = "",
  } = options;

  const endpoint =
    "/industries/" +
    industry +
    "/assets/" +
    "?language=" +
    language +
    (tags ? "&tags=" + tags : "") +
    (categories ? "&categories=" + categories : "") +
    (prefix ? "&prefix=" + prefix : "") +
    (directory ? "&directory=" + directory : "") +
    (updated_since ? "&updated_since=" + updated_since : "") +
    (user_id ? "&user_id=" + user_id : "") +
    (related_content ? "&related_content=" + related_content : "") +
    (page >= 0 ? "&page=" + page : "") +
    (limit >= 0 ? "&limit=" + limit : "");

  return get(endpoint)
    .then((result: KcmAssetArrayResponse) => {
      if (result.data) {
        return result.data;
      }
      return { error: Errors.ContentsErr };
    })
    .catch(() => {
      return { error: Errors.ContentsErr };
    });
}

function getMostRecentBrunchNLearn(): Promise<KcmContent | null> {
  //get most recent brunch and learn
  return getContents({
    page: 0,
    types: "webinar-replay",
    categories: "brunch-n-learn",
    webinar_time_before: new Date().toISOString(),
  })
    .then((results) => {
      if (!results.error && results.content && results.content.length > 0) {
        const sortedContent = results.content.sort(
          (a: KcmContent, b: KcmContent) => {
            if (
              Date.parse(a.webinar_time ?? "") <
              Date.parse(b.webinar_time ?? "")
            ) {
              return 1;
            }
            return -1;
          }
        );
        return sortedContent[0];
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
}

function getMostRecentGettingStartedWebinar(): Promise<KcmContent | null> {
  //get most recent brunch and learn
  return getContents({
    page: 0,
    types: "webinar-replay",
    categories: "getting-started-webinar",
    webinar_time_before: new Date().toISOString(),
  })
    .then((results) => {
      if (!results.error && results.content && results.content.length > 0) {
        const sortedContent = results.content.sort(
          (a: KcmContent, b: KcmContent) => {
            if (
              Date.parse(a.webinar_time ?? "") <
              Date.parse(b.webinar_time ?? "")
            ) {
              return 1;
            }
            return -1;
          }
        );
        return sortedContent[0];
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
}

function getLatestMushContent(
  options: ContentQueryParams = {}
): Promise<KcmContent | null> {
  const finalOptions = { ...options, ...{ page: 0, types: "must-share" } };
  return getContents(finalOptions)
    .then((results) => {
      if (!results.error && results.content && results.content.length > 0) {
        return results.content[0];
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
}

function getLatestAlertContent(): Promise<KcmContent[] | null> {
  const options = { page: 0, types: "alert", status: ["published"] };
  return getContents(options)
    .then((results) => {
      if (!results.error && results.content && results.content.length > 0) {
        return results.content;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
}
